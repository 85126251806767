import { sortBy } from 'lodash';

import { IQuestionResponse } from './question_response';
import { IQuestion, QuestionToken } from './questions';
import { RatingTrend, RatingTrendMap } from './rating_scales';
import { IScheduledEvent } from './scheduled_event';
import {
  IOrganization,
  IUser,
  OrganizationToken,
  UserMapItem,
  UserToken,
} from './types';

export enum SurveyType {
  BASIC = 'BASIC',
  CHECK_IN = 'CHECK_IN',
}

export const SurveyTypeLabels = {
  [SurveyType.BASIC]: 'survey',
  [SurveyType.CHECK_IN]: 'check-in',
};

export enum SurveySectionType {
  ONE_ON_ONE_PREPAREDNESS = 'ONE_ON_ONE_PREPAREDNESS',
}

export const isSurveySectionType = (
  section: any,
): section is SurveySectionType => {
  return Object.values(SurveySectionType).includes(section);
};

export type SurveyCycleToken = `sc_${string}`;

export interface ISurveyCycle {
  token: SurveyCycleToken;
  name: string;
  anonymous: boolean;
  ownerToken?: UserToken;
  owner?: IUser;
  participants: ISurveyParticipant[];
  questions: IQuestion[];
  sortedSections: Array<QuestionToken | SurveySectionType>;
  organizationToken: OrganizationToken;
  correlationToken?: SurveyCycleToken;
  type: SurveyType;
  startEvent: IScheduledEvent;
  reminderEvent?: IScheduledEvent;
  finalReminderEvent?: IScheduledEvent;
  endEvent: IScheduledEvent;
  startedDate?: Date;
  endedDate?: Date;
}

export type SurveyParticipantToken = `sp_${string}`;

export interface ISurveyParticipant {
  token: SurveyParticipantToken;
  organizationToken: OrganizationToken;
  organization: IOrganization;
  cycleToken: SurveyCycleToken;
  cycle: ISurveyCycle;
  userToken: UserToken;
  user: IUser;
  responses?: IQuestionResponse[];
  submittedDate?: Date;
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}

export interface ListSurveyCyclesResponse {
  cycles: ISurveyCycle[];
}

export interface ISurveyDetails {
  surveyCycle: ISurveyCycle;
  participants?: ISurveyParticipant[];
  summaries?: Record<QuestionToken, any>;
  participantRatingTrendMaps?: Record<UserToken, RatingTrendMap>;
  participantMap?: Record<SurveyParticipantToken, IUser>;
  averageRatingTrendMap: Record<QuestionToken, RatingTrend>;
  totalParticipants: number;
}

export interface ISurveySummaryResponse {
  surveyCycle: ISurveyCycle;
  summaries: any;
  averageRatingTrendMap: Record<QuestionToken, RatingTrend>;
  totalParticipants: number;
}

export interface ITeamSurveySummaryResponse {
  surveyCycle: ISurveyCycle;
  summaries: any;
  participants: ISurveyParticipant[];
  participantRatingTrendMaps: Record<`u_${string}`, RatingTrendMap> | undefined;
  participantMap: Record<UserToken, any>;
  averageRatingTrendMap: Record<UserToken, any>;
  totalParticipants: number;
}

export interface ISurveySummaryRow {
  token: SurveyCycleToken;
  name: string;
  startedDate?: Date | string;
  endedDate?: Date | string;
  numQuestions: number;
  numParticipants: number;
  numSubmitted: number;
  owner?: UserMapItem;
}

export interface IListSurveysResponse {
  rows: ISurveySummaryRow[];
}

export const sortedSurveyQuestions = ({
  questions,
  sortedSections,
}: Pick<ISurveyCycle, 'questions' | 'sortedSections'>) => {
  const questionPositions: Record<QuestionToken, number> = {};
  sortedSections.forEach((token, index) => {
    questionPositions[token] = index;
  });

  return sortBy(questions, (question) => questionPositions[question.token]);
};
