import { GithubOutlined } from '@ant-design/icons';
import { FLINT_USER, GITHUB_USER, IUser, UserMapItem } from '@shared/types';
import { FlintLogo } from '@web/app/Logo/Logo';
import { initials } from '@web/common/initials';
import { Avatar } from 'antd';
import * as React from 'react';

import { Column } from './layout';

export const UserAvatar: React.FC<{
  user?: IUser | UserMapItem;
  name?: string;
  style?: React.CSSProperties;
  size?: number;
  backgroundColor?: string;
  className?: string;
}> = ({
  user,
  name,
  style,
  backgroundColor = '#888',
  size = 48,
  className,
}) => {
  if (user?.token === FLINT_USER.token) {
    return <FlintAvatar size={size} style={style} />;
  } else if (user?.token === GITHUB_USER.token) {
    return <GithubAvatar size={size} style={style} />;
  }

  const imageSrc = user?.slackImageUrl;
  const displayName = user?.name ?? name;
  return (
    <Avatar
      size={size}
      icon={imageSrc ? <img src={imageSrc} /> : null}
      style={{
        flexGrow: 0,
        flexShrink: 0,
        backgroundColor,
        border: 0,
        fontSize: Math.max(12, Math.round((size * 3) / 8)),
        ...style,
      }}
      className={className}
    >
      {!imageSrc && displayName ? initials(displayName) : '-'}
    </Avatar>
  );
};

export const FlintAvatar: React.FC<{
  style?: React.CSSProperties;
  size?: number;
}> = ({ style, size = 48 }) => {
  const paddingTop = 5;
  return (
    <Avatar
      size={size}
      icon={null}
      style={{
        flexGrow: 0,
        flexShrink: 0,
        backgroundColor: '#eee',
        border: 0,
        fontSize: Math.max(12, Math.round((size * 3) / 8)),
        ...style,
      }}
    >
      <Column style={{ width: size, height: size, paddingTop }}>
        <FlintLogo
          size={`${Math.max(24, size - 2 * paddingTop)}px`}
          style={{ position: 'relative', top: -1 }}
        />
      </Column>
    </Avatar>
  );
};

export const GithubAvatar: React.FC<{
  style?: React.CSSProperties;
  size?: number;
}> = ({ style, size = 48 }) => {
  return (
    <Avatar
      size={size}
      icon={null}
      style={{
        flexGrow: 0,
        flexShrink: 0,
        backgroundColor: '#333',
        border: 0,
        fontSize: Math.max(12, Math.round((size * 3) / 8)),
        ...style,
      }}
    >
      <Column style={{ width: size, height: size }}>
        <GithubOutlined style={{ fontSize: size }} />
      </Column>
    </Avatar>
  );
};
