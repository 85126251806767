import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { IBedrockEnrollment } from '@shared/bedrock';
import { UserToken } from '@shared/types';
import { EditTextModal } from '@web/components/EditTextModal';
import { UserAvatar } from '@web/components/UserAvatar';
import { Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { sortBy } from 'lodash';
import * as React from 'react';

export const EnrollmentsTable: React.FC<{
  enrollments: IBedrockEnrollment[];
  onEditGithub?: (userToken: UserToken, githubLogin: string) => void;
  onDelete: (enrollment: IBedrockEnrollment) => void;
}> = ({ enrollments, onEditGithub, onDelete }) => {
  const columns: ColumnsType<IBedrockEnrollment> = [
    {
      dataIndex: ['user', 'slackImageUrl'],
      width: 50,
      render(_, enrollment) {
        return <UserAvatar user={enrollment.user} size={24} />;
      },
    },
    {
      dataIndex: ['user', 'name'],
      title: 'Name',
    },
    {
      dataIndex: ['user', 'githubLogin'],
      title: 'GitHub Username',
      render: (_, enrollment) => (
        <EditableGithubLoginCell
          enrollment={enrollment}
          onSave={(githubLogin: string) => {
            onEditGithub(enrollment.user.token, githubLogin);
          }}
        />
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      render: (_, enrollment) => {
        return (
          <Button
            type="text"
            onClick={() => {
              onDelete(enrollment);
            }}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];
  const sortedEnrollments = sortBy(enrollments, 'user.name');
  return (
    <Table rowKey="token" columns={columns} dataSource={sortedEnrollments} />
  );
};

const EditableGithubLoginCell: React.FC<{
  enrollment: IBedrockEnrollment;
  onSave: (githubLogin: string) => void;
}> = ({ enrollment, onSave }) => {
  const [githubLogin, setGithubLogin] = React.useState(
    enrollment.user.githubLogin ?? '',
  );
  const [open, setOpen] = React.useState(false);

  const handleSave = (newGithubLogin) => {
    setOpen(false);
    setGithubLogin(newGithubLogin);
    onSave(newGithubLogin);
  };

  return (
    <Row gap={3}>
      <Text>{githubLogin}</Text>
      <Button
        title="Edit GitHub username"
        type="text"
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        <EditOutlined />
      </Button>
      {open && (
        <EditTextModal
          onCancel={() => {
            setOpen(false);
          }}
          onEnter={() => {}}
          onSave={handleSave}
          initialText={githubLogin}
          title={`Set ${enrollment.user.name}'s GitHub Username`}
          width="400px"
        />
      )}
    </Row>
  );
};
