import { giveFeedbackResponse } from '@client/FeedbackClient';
import {
  saveDraftResponse,
  useDraftResponses,
} from '@client/FeedbackRequestClient';
import { useQuestionSet } from '@client/QuestionSetsClient';
import { UserFeedbackResponse } from '@shared/feedback';
import { IQuestionResponse } from '@shared/question_response';
import { QuestionToken } from '@shared/questions';
import {
  FeedbackType,
  IFeedbackRequest,
  ITask,
  IUser,
  UserMapItem,
} from '@shared/types';
import { message } from 'antd';
import React from 'react';

import { GiveRequestedFeedbackForm } from './GiveRequestedFeedbackForm';

export interface Props {
  user: IUser;
  receiver?: UserMapItem;
  requester?: UserMapItem;
  task: ITask;
  feedbackRequest: IFeedbackRequest;
  onSuccess: () => void;
  onCancel: () => void;
}
export const QuestionSetFeedbackForm: React.FC<Props> = ({
  user,
  receiver,
  requester,
  task,
  feedbackRequest,
  onCancel,
  onSuccess,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const { data: draftResponses, mutate: invalidateDraftResponses } =
    useDraftResponses(feedbackRequest.token);
  if (!feedbackRequest?.questionSetToken) {
    throw new Error('Invalid template feedback request');
  }

  const { data: questionSet } = useQuestionSet(
    feedbackRequest.questionSetToken,
  );
  // if (!feedbackRequest || !response || !draftResponses || !questionSet) {
  if (!feedbackRequest || !questionSet || !draftResponses) {
    return null;
  }

  const handleSubmit = async (responses: UserFeedbackResponse[]) => {
    setIsSaving(true);

    try {
      await giveFeedbackResponse(feedbackRequest.token, responses);
      void message.success('Success');
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleAutosave = async (
    questionToken: QuestionToken,
    response: Pick<IQuestionResponse, 'text' | 'rating'>,
  ) => {
    await saveDraftResponse(
      feedbackRequest.token,
      questionToken,
      response.text,
      response.rating,
    );
    void invalidateDraftResponses();
  };

  return (
    <GiveRequestedFeedbackForm
      receiver={receiver}
      questionSet={questionSet}
      responses={draftResponses}
      parameters={{
        receiver: receiver?.name ?? '',
        requester: requester?.name ?? '',
      }}
      disabled={isSaving}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      isReviewCycleRequest={!!feedbackRequest.reviewCycleToken}
      isUpwardFeedback={feedbackRequest.type === FeedbackType.UPWARD}
      onAutosave={handleAutosave}
    />
  );
};
