import {
  CheckCircleFilled,
  ScheduleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { IBedrockReport } from '@backend/bedrock/IBedrockReport';
import {
  clearBedrockPrompt,
  prepareBedrockBatch,
  prepareBedrockRun,
  sendBedrockBatch,
  sendBedrockIndividualSummary,
  sendBedrockManagerSummary,
  sendBedrockRun,
  useBedrockRuns,
} from '@client/BedrockConfigurationClient';
import {
  BedrockBatchToken,
  BedrockJournalSpec,
  BedrockMechanism,
  BedrockReportSendMode,
  IBedrockRun,
} from '@shared/bedrock';
import { exhaustiveCheck } from '@shared/exhaustiveCheck';
import { formatLongDate } from '@shared/formatLongDate';
import { IUser, OrganizationToken } from '@shared/types';
import { KnownBlock } from '@slack/types';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import CodeMirror from '@uiw/react-codemirror';
import { PageContent } from '@web/app/Page';
import { successColor } from '@web/app/styles/ColorStyles';
import { BackButton } from '@web/components/BackButton';
import { Markdown } from '@web/components/Markdown';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { UserMessage } from '@web/components/UserMessage';
import { Column, GrowingSpacer, Row } from '@web/components/layout';
import { Header2, Header3, Text } from '@web/components/typography';
import {
  Button,
  Descriptions,
  DescriptionsProps,
  Divider,
  Modal,
  Popconfirm,
  Table,
  Tooltip,
  message,
} from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import TextArea from 'antd/es/input/TextArea';
import { ColumnsType } from 'antd/lib/table';
import { capitalize } from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { BedrockTabs } from './BedrockTabs';
import { PrepareRunFormData, PrepareRunModal } from './PrepareRunModal';
import { SendPreparedRunModal } from './SendPreparedRunModal';
import {
  ISendManagerSummaryFormData,
  SendSummaryButton,
  SummaryType,
} from './SendSummaryButton';

export const BedrockBatchPage: React.FC = () => {
  const { organizationToken, batchToken } = useParams<{
    organizationToken: OrganizationToken;
    batchToken: BedrockBatchToken;
  }>();

  const { data: response, mutate: reloadRuns } = useBedrockRuns(
    organizationToken,
    batchToken,
  );
  const [showPrepareModal, setShowPrepareModal] = React.useState(false);
  const [showSendModal, setShowSendModal] = React.useState(false);

  const handlePrepareRun = async (
    run: IBedrockRun,
    { runOpts, mechanism }: PrepareRunFormData,
  ) => {
    try {
      await prepareBedrockRun(
        organizationToken,
        batchToken,
        run.token,
        mechanism,
        runOpts,
      );
      await reloadRuns();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSendRun = async (
    run: IBedrockRun,
    mechanism: BedrockMechanism,
    sendMode: BedrockReportSendMode,
  ) => {
    try {
      await sendBedrockRun(
        organizationToken,
        batchToken,
        run.token,
        mechanism,
        sendMode,
      );
      await reloadRuns();
      setShowSendModal(false);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handlePrepareBatch = async ({
    runOpts,
    mechanism,
  }: PrepareRunFormData) => {
    try {
      await prepareBedrockBatch(
        organizationToken,
        batchToken,
        mechanism,
        runOpts,
      );
      await reloadRuns();
      setShowPrepareModal(false);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSendBatch = async (
    mechanism: BedrockMechanism,
    sendMode: BedrockReportSendMode,
  ) => {
    try {
      await sendBedrockBatch(
        organizationToken,
        batchToken,
        mechanism,
        sendMode,
      );
      void message.success('Sent');
      setShowSendModal(false);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSendSummary = async (
    { sendMode, type }: ISendManagerSummaryFormData,
    run?: IBedrockRun,
  ) => {
    try {
      if (type === SummaryType.INDIVIDUAL) {
        await sendBedrockIndividualSummary(
          organizationToken,
          batchToken,
          sendMode,
          run?.token,
        );
      } else if (type === SummaryType.MANAGER) {
        await sendBedrockManagerSummary(
          organizationToken,
          batchToken,
          sendMode,
          run?.token,
        );
      } else {
        exhaustiveCheck(type);
      }
      void message.success('Sent');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleClearPrompt = async (run?: IBedrockRun) => {
    try {
      await clearBedrockPrompt(organizationToken, batchToken, run?.token);
      await reloadRuns();
      void message.success('Cleared');
    } catch (error) {
      void message.error('Error');
    }
  };

  const items: DescriptionsProps['items'] = response?.batch
    ? [
        {
          key: 'type',
          label: 'Type',
          children: response?.batch?.type,
        },
        {
          key: 'startDate',
          label: 'Start Date',
          children: formatLongDate(response.batch.startDate),
        },
        {
          key: 'endDate',
          label: 'End Date',
          children: formatLongDate(response.batch.endDate),
        },
      ]
    : [];

  return (
    <PageContent>
      <PageHeader title="Bedrock" />
      <BedrockTabs />
      <Pane>
        <Column gap={24}>
          <Column gap={12}>
            <BackButton defaultBackTo=".." />
            <Descriptions items={items} />
            <Row>
              <Header2>Runs</Header2>
              <GrowingSpacer />
              <Row gap={6}>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowPrepareModal(true);
                  }}
                >
                  <ScheduleOutlined /> Prepare
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowSendModal(true);
                  }}
                >
                  <SendOutlined /> Send
                </Button>
                <SendSummaryButton onSend={handleSendSummary}>
                  Send Summary
                </SendSummaryButton>
              </Row>
            </Row>
            <BedrockRunsTable
              runs={response?.runs}
              onPrepareRun={handlePrepareRun}
              onSendRun={handleSendRun}
              onSendSummary={handleSendSummary}
              onClearPrompt={handleClearPrompt}
            />
          </Column>
        </Column>
      </Pane>
      <PrepareRunModal
        open={showPrepareModal}
        onCancel={() => {
          setShowPrepareModal(false);
        }}
        onSubmit={handlePrepareBatch}
      />
      <SendPreparedRunModal
        title="Send Batch"
        open={showSendModal}
        onCancel={() => {
          setShowSendModal(false);
        }}
        onSubmit={handleSendBatch}
      />
    </PageContent>
  );
};

export const BlockPreviewCell: React.FC<{ blocks?: KnownBlock[] }> = ({
  blocks,
}) => {
  const [showPreview, setShowPreview] = React.useState(false);

  if (!blocks) {
    return undefined;
  }
  const json = JSON.stringify(blocks, ' ' as any, 2).trim();
  const closeModal = () => {
    setShowPreview(false);
  };
  return (
    <Column>
      <CheckCircleFilled
        onClick={() => {
          setShowPreview(true);
        }}
        style={{ color: successColor.string(), alignSelf: 'center' }}
      />
      <Modal
        title="Preview"
        open={showPreview}
        onCancel={closeModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Close"
        onOk={closeModal}
      >
        <CodeMirror value={json} height="500px" theme={vscodeDark} />
      </Modal>
    </Column>
  );
};

export const JournalPreviewCell: React.FC<{
  journals?: BedrockJournalSpec[];
}> = ({ journals }) => {
  const [showPreview, setShowPreview] = React.useState(false);
  const closeModal = () => {
    setShowPreview(false);
  };
  if (!journals) {
    return undefined;
  }

  return (
    <Column gap={24}>
      <Text
        onClick={
          journals.length > 0
            ? () => {
                setShowPreview(true);
              }
            : undefined
        }
        style={{ cursor: journals.length > 0 ? 'pointer' : undefined }}
      >
        {journals.length} entries
      </Text>
      <Modal
        title="Preview"
        open={showPreview}
        onCancel={closeModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Close"
        onOk={closeModal}
      >
        <Column gap={12}>
          {journals.map((journal, index) => {
            return (
              <Column key={index} style={{ maxWidth: 824 }}>
                <Header3>{journal.title}</Header3>
                <Text>{formatLongDate(journal.date)}</Text>
                <Markdown value={journal.descriptionMarkdown} />
                {index !== journals.length - 1 ? <Divider /> : undefined}
              </Column>
            );
          })}
        </Column>
      </Modal>
    </Column>
  );
};

export const RawPreviewCell: React.FC<{
  raw?: string;
}> = ({ raw }) => {
  const [showPreview, setShowPreview] = React.useState(false);
  const json = raw ? JSON.stringify(JSON.parse(raw), ' ' as any, 2).trim() : '';

  const closeModal = () => {
    setShowPreview(false);
  };
  if (!raw) {
    return undefined;
  }

  return (
    <Column gap={24}>
      <CheckCircleFilled
        onClick={() => {
          setShowPreview(true);
        }}
        style={{ color: successColor.string(), alignSelf: 'center' }}
      />
      <Modal
        title="Preview"
        open={showPreview}
        onCancel={closeModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Close"
        onOk={closeModal}
      >
        <CodeMirror value={json} height="500px" theme={vscodeDark} />
      </Modal>
    </Column>
  );
};

const PromptPreviewCell: React.FC<{
  prompt?: IBedrockRun['prompt'];
  onClearPrompt: () => Promise<void>;
}> = ({ prompt, onClearPrompt }) => {
  const [showPreview, setShowPreview] = React.useState(false);
  const [isClearing, setIsClearing] = React.useState(false);
  const [isRaw, setIsRaw] = React.useState(false);

  const json = prompt ? JSON.stringify(prompt, ' ' as any, 2).trim() : '';

  const closeModal = () => {
    setShowPreview(false);
  };

  const handleClearPrompt = async () => {
    setIsClearing(true);
    try {
      await onClearPrompt();
      void closeModal();
    } catch (error) {
      void message.error('Failed to clear prompt');
    } finally {
      setIsClearing(false);
    }
  };
  if (!prompt) {
    return undefined;
  }

  return (
    <Column gap={24}>
      <CheckCircleFilled
        onClick={() => {
          setShowPreview(true);
        }}
        style={{ color: successColor.string(), alignSelf: 'center' }}
      />
      <Modal
        title="Preview"
        open={showPreview}
        onCancel={closeModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Close"
        onOk={closeModal}
      >
        <Column>
          <Popconfirm
            title="Clear prompt"
            description="Are you sure you want to clear the prompt of this run?"
            disabled={isClearing}
            onConfirm={handleClearPrompt}
          >
            <Button>Clear Prompt</Button>
          </Popconfirm>

          <Checkbox
            checked={isRaw}
            onChange={() => {
              setIsRaw(!isRaw);
            }}
          >
            Raw
          </Checkbox>
        </Column>
        {isRaw ? (
          <CodeMirror value={json} height="500px" theme={vscodeDark} />
        ) : (
          <PromptMessageCards prompt={prompt} />
        )}
      </Modal>
    </Column>
  );
};

const PromptMessageCards: React.FC<{ prompt: IBedrockRun['prompt'] }> = ({
  prompt,
}) => {
  return (
    <Column gap={12}>
      {prompt.map((msg, index) => {
        switch (msg.role) {
          case 'system':
            return (
              <Column key={index} gap={3}>
                <Header3>System</Header3>
                <TextArea
                  autoSize
                  value={
                    typeof msg.content === 'string'
                      ? msg.content
                      : msg.content.join('\n')
                  }
                />
              </Column>
            );
          case 'user':
            return (
              <Column key={index} gap={3}>
                <Header3>User</Header3>
                <TextArea
                  autoSize
                  value={
                    typeof msg.content === 'string'
                      ? msg.content
                      : msg.content.join('\n')
                  }
                />
              </Column>
            );
          default:
            return (
              <Column key={index} gap={3}>
                <Header3>{capitalize(msg.role)} (unsupported preview)</Header3>
                <TextArea autoSize value={JSON.stringify(msg)}></TextArea>
              </Column>
            );
        }
      })}
    </Column>
  );
};

export const BedrockRunsTable: React.FC<{
  runs?: IBedrockRun[];
  onClearPrompt: (run: IBedrockRun) => Promise<void>;
  onPrepareRun: (
    run: IBedrockRun,
    formData: PrepareRunFormData,
  ) => Promise<void>;
  onSendRun: (
    run: IBedrockRun,
    mechanism: BedrockMechanism,
    sendMode: BedrockReportSendMode,
  ) => Promise<void>;
  onSendSummary: (
    formData: ISendManagerSummaryFormData,
    run: IBedrockRun,
  ) => Promise<void>;
}> = ({ runs, onClearPrompt, onPrepareRun, onSendRun, onSendSummary }) => {
  const columns: ColumnsType<IBedrockRun> = [
    {
      dataIndex: 'user',
      title: '',
      render: (user: IUser) => <UserMessage user={user} />,
    },
    {
      key: 'messages',
      width: 100,
      title: (
        <Tooltip title="The number of slack messages attributed to the user, and the total messages including not from the user the last time this run was prepared">
          # Msg
        </Tooltip>
      ),
      render: (_, run) => {
        if (run.userMessagesSeen === null) {
          return run.messagesSeen;
        }
        return `${run.userMessagesSeen} (${run.messagesSeen})`;
      },
    },
    {
      dataIndex: 'pullRequestsSeen',
      width: 100,
      title: (
        <Tooltip title="The number of pull requests used the last time this run was prepared">
          # PR
        </Tooltip>
      ),
    },
    {
      dataIndex: 'journal',
      title: 'Journal',
      width: 150,
      render: (journals?: BedrockJournalSpec[]) => {
        return <JournalPreviewCell journals={journals} />;
      },
    },
    {
      dataIndex: 'journalSentAt',
      title: 'Journal Sent',
      width: 150,
      render: (journalSentAt?: Date) => {
        if (!journalSentAt) {
          return undefined;
        }
        return <Text>{formatLongDate(journalSentAt)}</Text>;
      },
    },
    {
      dataIndex: 'managerSummarySentAt',
      title: 'Manager Summary Sent',
      align: 'center',
      width: 150,
      render: (managerSummarySentAt?: Date) => {
        if (!managerSummarySentAt) {
          return undefined;
        }
        return <Text>{formatLongDate(managerSummarySentAt)}</Text>;
      },
    },
    {
      dataIndex: 'individualSummarySentAt',
      title: 'IC Summary Sent',
      align: 'center',
      width: 150,
      render: (individualSummarySentAt?: Date) => {
        if (!individualSummarySentAt) {
          return undefined;
        }
        return <Text>{formatLongDate(individualSummarySentAt)}</Text>;
      },
    },
    {
      dataIndex: 'raw',
      title: 'Raw',
      align: 'center',
      width: 100,
      render: (raw?: string) => {
        return <RawPreviewCell raw={raw} />;
      },
    },
    {
      dataIndex: 'report',
      title: 'Report',
      align: 'center',
      width: 100,
      render: (report?: IBedrockReport) => {
        return (
          <RawPreviewCell
            raw={report ? JSON.stringify(report, null, 2) : null}
          />
        );
      },
    },
    {
      dataIndex: 'prompt',
      title: 'Prompt',
      align: 'center',
      width: 150,
      render: (prompt: IBedrockRun['prompt'] | undefined, run) => {
        return (
          <PromptPreviewCell
            onClearPrompt={async () => {
              await onClearPrompt(run);
            }}
            prompt={prompt}
          />
        );
      },
    },
    {
      key: 'actions',
      title: '',
      render: (_, run) => {
        return (
          <RowActions
            run={run}
            onPrepareRun={onPrepareRun}
            onSendRun={onSendRun}
            onSendSummary={onSendSummary}
          />
        );
      },
    },
  ];
  return (
    <Table
      rowKey="token"
      columns={columns}
      dataSource={runs}
      loading={!runs}
      scroll={{
        x: true,
      }}
      pagination={false}
    />
  );
};

const RowActions: React.FC<{
  run: IBedrockRun;
  onPrepareRun: (
    run: IBedrockRun,
    formData: PrepareRunFormData,
  ) => Promise<void>;
  onSendRun: (
    run: IBedrockRun,
    mechanism: BedrockMechanism,
    sendMode: BedrockReportSendMode,
  ) => Promise<void>;
  onSendSummary: (
    formData: ISendManagerSummaryFormData,
    run: IBedrockRun,
  ) => Promise<void>;
}> = ({ run, onPrepareRun, onSendRun, onSendSummary }) => {
  const [showPrepareModal, setShowPrepareModal] = React.useState(false);
  const [showSendModal, setShowSendModal] = React.useState(false);

  const handlePrepare = async (formData: PrepareRunFormData) => {
    try {
      await onPrepareRun(run, formData);
      setShowPrepareModal(false);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSend = async (
    mechanism: BedrockMechanism,
    sendMode: BedrockReportSendMode,
  ) => {
    try {
      await onSendRun(run, mechanism, sendMode);
      void message.success('Sent');
      setShowSendModal(false);
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSendSummary = async (formData: ISendManagerSummaryFormData) => {
    await onSendSummary(formData, run);
  };

  return (
    <Row gap={12}>
      <Button
        onClick={() => {
          setShowPrepareModal(true);
        }}
      >
        <ScheduleOutlined />
      </Button>
      <Button
        onClick={() => {
          setShowSendModal(true);
        }}
      >
        <SendOutlined />
      </Button>
      <SendSummaryButton onSend={handleSendSummary} />
      <SendPreparedRunModal
        title="Send Run"
        open={showSendModal}
        onSubmit={handleSend}
        onCancel={() => {
          setShowSendModal(false);
        }}
      />
      <PrepareRunModal
        open={showPrepareModal}
        onSubmit={handlePrepare}
        onCancel={() => {
          setShowPrepareModal(false);
        }}
      />
    </Row>
  );
};
