import { Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';

interface Props {
  onCancel: () => void;
  onSave: (text: string) => void;
  onEnter?: () => void;
  initialText: string;
  title: string;
  width?: string | number;
}

export const EditTextModal: React.FC<Props> = ({
  initialText,
  onSave,
  onCancel,
  onEnter,
  title,
  width = '800px',
}) => {
  const [text, setText] = React.useState(initialText);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && onEnter) {
      e.stopPropagation();
      e.preventDefault();
      onEnter?.();
    }
  };

  return (
    <Modal
      title={title}
      open={true}
      onOk={() => {
        onSave(text);
      }}
      afterClose={onCancel}
      onCancel={onCancel}
      width={width}
      okText="Update"
    >
      <TextArea
        value={text}
        onChange={(e) => {
          setText(e.currentTarget.value);
        }}
        onKeyDown={handleKeyDown}
        style={{ height: 200, maxHeight: '80vh' }}
        autoSize
      />
    </Modal>
  );
};
