import { useReviewCycleAdminStatus } from '@client/ReviewCyclesClient';
import { Feature } from '@shared/features';
import { productDetails } from '@shared/reflections';
import { IReviewCycle, ReviewCycleToken } from '@shared/review-cycles';
import { useApi } from '@web/common/useApi';
import { useFeature } from '@web/common/useFeature';
import { BackLink } from '@web/components/BackButton';
import { InlineTabLink, InlineTabs } from '@web/components/InlineTabs';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header1 } from '@web/components/typography';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useMatch, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AdminPageContent } from '../admin/AdminPageContent';
import { AdminReviewCycleStatus } from './AdminReviewCycleStatus';
import { DashboardActionsMenu } from './DashboardActionsMenu';
import { EditQuestionSet } from './EditQuestionSet/EditQuestionSet';
import { EditReviewCycleForm } from './EditReviewCycleForm';
import { ParticipantSummaryTable } from './ParticipantsTableSummary';
import { ReviewCycleStateTag } from './ReviewCycleStateTag';
import { TeamsTableSummary } from './TeamsTableSummary';

const AdminReviewCycleDashboardPage: React.FC = () => {
  const { booleanValue: upwardFeedbackEnabled } = useFeature(
    Feature.UPWARD_FEEDBACK_ENABLED,
  );
  const { reviewCycleToken } = useParams<{
    reviewCycleToken?: ReviewCycleToken;
  }>();
  const { data: reviewCycle, mutate: reloadReviewCycle } = useApi<IReviewCycle>(
    `/review-cycles/${reviewCycleToken}`,
  );
  const { data: reviewCycleStatus, mutate: reloadReviewCycleStatus } =
    useReviewCycleAdminStatus(reviewCycleToken);
  const isTeamPage = !!useMatch(
    '/admin/review-cycles/:reviewCycleToken/dashboard/teams',
  );
  const isPeerReviewPage = !!useMatch(
    '/admin/review-cycles/:reviewCycleToken/peer',
  );
  const isUpwardReviewPage = !!useMatch(
    '/admin/review-cycles/:reviewCycleToken/upward',
  );
  const isSelfReviewPage = !!useMatch(
    '/admin/review-cycles/:reviewCycleToken/self',
  );
  const isManagerReviewPage = !!useMatch(
    '/admin/review-cycles/:reviewCycleToken/manager',
  );
  const isSettingsPage = !!useMatch(
    '/admin/review-cycles/:reviewCycleToken/settings',
  );

  if (!reviewCycle) {
    return (
      <AdminPageContent>
        <Skeleton />
      </AdminPageContent>
    );
  }

  const handleDashboardChange = () => {
    void reloadReviewCycle();
    void reloadReviewCycleStatus();
  };
  const upwardFeedbackAllowed =
    !!reviewCycle.upwardReviewQuestionSetToken && upwardFeedbackEnabled;

  return (
    <AdminPageContent>
      <Column gap={6}>
        <Row>
          <BackLink to={`/admin/review-cycles`} />
        </Row>
        <Row gap={6}>
          <Header1>{reviewCycle.name}</Header1>
          <ReviewCycleStateTag reviewCycle={reviewCycle} />
          <GrowingSpacer />
          <DashboardActionsMenu
            reviewCycle={reviewCycle}
            onChange={handleDashboardChange}
          />
        </Row>
      </Column>
      <Column>
        <AdminReviewCycleStatus reviewCycleStatus={reviewCycleStatus} />
        <Spacer />
        <InlineTabs>
          <InlineTabLink
            to={`/admin/review-cycles/${reviewCycleToken}/dashboard/participants`}
          >
            Participants
          </InlineTabLink>
          <InlineTabLink
            to={`/admin/review-cycles/${reviewCycleToken}/dashboard/teams`}
          >
            Teams
          </InlineTabLink>
          <InlineTabLink
            to={`/admin/review-cycles/${reviewCycleToken}/settings`}
          >
            Settings
          </InlineTabLink>
          {reviewCycle.peerReviewCycleEnabled && (
            <InlineTabLink to={`/admin/review-cycles/${reviewCycleToken}/peer`}>
              Peer Review
            </InlineTabLink>
          )}
          {upwardFeedbackAllowed && reviewCycle.upwardReviewCycleEnabled && (
            <InlineTabLink
              to={`/admin/review-cycles/${reviewCycleToken}/upward`}
            >
              Upward Feedback
            </InlineTabLink>
          )}
          {reviewCycle.selfReflectionCycleEnabled && (
            <InlineTabLink to={`/admin/review-cycles/${reviewCycleToken}/self`}>
              Self{' '}
              {productDetails(reviewCycle.selfReflectionProductName).titleCase}
            </InlineTabLink>
          )}
          {reviewCycle.managerReflectionCycleEnabled && (
            <InlineTabLink
              to={`/admin/review-cycles/${reviewCycleToken}/manager`}
            >
              Manager{' '}
              {productDetails(reviewCycle.managerReviewProductName).titleCase}
            </InlineTabLink>
          )}
        </InlineTabs>
        <BorderedContainer>
          {isTeamPage ? (
            <TeamsTableSummary reviewCycle={reviewCycle} />
          ) : isSettingsPage ? (
            <EditReviewCycleForm
              reviewCycleToken={reviewCycle.token}
              onSave={handleDashboardChange}
            />
          ) : isPeerReviewPage ? (
            <EditQuestionSet
              questionSetToken={reviewCycle.peerReviewTemplateToken}
              readonly={!!reviewCycle.peerReviewFeedbackRequested}
            />
          ) : isUpwardReviewPage ? (
            <EditQuestionSet
              questionSetToken={reviewCycle.upwardReviewQuestionSetToken}
              readonly={!!reviewCycle.upwardReviewFeedbackRequested}
            />
          ) : isSelfReviewPage ? (
            <EditQuestionSet
              questionSetToken={reviewCycle.selfReflectionQuestionSetToken}
              readonly={!!reviewCycle.selfReflectionCycleStarted}
            />
          ) : isManagerReviewPage ? (
            <EditQuestionSet
              questionSetToken={reviewCycle.managerReflectionQuestionSetToken}
              readonly={!!reviewCycle.managerReflectionCycleStarted}
            />
          ) : (
            <ParticipantSummaryTable reviewCycle={reviewCycle} />
          )}
        </BorderedContainer>
      </Column>
    </AdminPageContent>
  );
};

export default AdminReviewCycleDashboardPage;

const BorderedContainer = styled.section`
  border: 1px solid #e0e0e0;
  border-radius: 0 var(--default-border-radius) var(--default-border-radius)
    var(--default-border-radius);
  padding: 18px;
  position: relative;
  top: -1px;
`;
