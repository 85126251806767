import { IBedrockReport } from '@backend/bedrock/IBedrockReport';
import { isToken } from '@shared/isToken';
import { IUser, OrganizationToken, UserToken } from '@shared/types';
import { KnownBlock } from '@slack/types';
import { ChatCompletionCreateParamsBase } from 'openai/resources/chat/completions';

import { IImpact } from './impacts';

export type BedrockConfigurationToken = `bc_${string}`;
export const isBedrockConfigurationToken = isToken('bc_');

export interface IBedrockConfiguration {
  token: BedrockConfigurationToken;
  organizationToken: OrganizationToken;
  enrollmentLimit: number;
}

export type BedrockEnrollmentToken = `be_${string}`;

export interface IBedrockEnrollment {
  token: BedrockEnrollmentToken;
  user: IUser;
  enrolledAt: Date;
}

export interface GetBedrockConfigurationResponse {
  configuration: IBedrockConfiguration;
  enrolledChannelCount: number;
  // <ChannelId, IChannelInfo> for all configured channels
  channels: IChannelInfo[];
}

export interface IChannelInfo {
  id: string;
  state: 'invalid' | 'active';
  name: string;
}

export type BedrockMechanism = 'journal' | 'dm';
export interface RunBedrockRequest {
  mechanism: BedrockMechanism;
  maxEntryCount: number;
  suggestedEntryCount: number;
  startDate: Date;
  endDate: Date;
  targetUserTokens?: UserToken[];
  adjacentMessageCount: number;
  type: BedrockReportType;
}

export type BedrockBatchToken = `bbt_${string}`;
export interface IBedrockBatch {
  token: BedrockBatchToken;
  type: BedrockReportType;
  startDate?: Date;
  endDate?: Date;
}

export type BedrockRunToken = `br_${string}`;
export interface IBedrockRun {
  token: BedrockRunToken;
  user: IUser;
  journal?: BedrockJournalSpec[];
  journalSentAt?: Date;
  blocks?: KnownBlock[];
  blocksSentAt?: Date;
  managerSummaryBlocks?: KnownBlock[];
  managerSummarySentAt?: Date;
  raw?: string;
  report?: IBedrockReport; // TODO: move type to shared.
  prompt?: ChatCompletionCreateParamsBase['messages'];
  messagesSeen?: number;
  userMessagesSeen?: number;
  pullRequestsSeen?: number;
}

export interface IBedrockBatchRow extends IBedrockBatch {
  totalRuns: number;
  totalSent: number;
}
export interface GetBedrockBatchesResponse {
  batches: IBedrockBatchRow[];
}

export interface GetBedrockRunsResponse {
  batch: IBedrockBatch;
  runs: IBedrockRun[];
}

export type BedrockJournalSpec = Pick<
  IImpact,
  'descriptionMarkdown' | 'title' | 'date'
>;

export type BedrockReportPresentation = Pick<
  IBedrockRun,
  'raw' | 'journal' | 'blocks' | 'prompt' | 'managerSummaryBlocks' | 'report'
>;

export type BedrockReportUpdate = BedrockReportPresentation &
  BedrockReportStatistics;

export type BedrockReportStatistics = Pick<
  IBedrockRun,
  'messagesSeen' | 'userMessagesSeen' | 'pullRequestsSeen'
>;

export interface CreateBedrockBatchRequest {
  type: BedrockReportType;
  startDate: Date;
  endDate: Date;
  targetUserTokens?: UserToken[];
}

export interface BedrockRunOpts {
  maxEntryCount: number;
  suggestedEntryCount: number;
  adjacentMessageCount: number;
  /** Whether the prompt used to produce the report should be saved to the `BedrockRun`. `runTokens` must be included when using `savePrompt`. */
  savePrompt?: boolean;
  /** The minimum number of messages required for a report to be generated for a given user (or run). */
  minimumRequiredMessagesPerReport?: number;
}

export type ChannelList = Array<{
  name: string;
  id: string;
}>;

export enum BedrockReportSendMode {
  ONCE = 'ONCE',
  FORCE = 'FORCE',
}
export enum BedrockReportType {
  BASIC = 'BASIC',
  WITH_MESSAGE = 'WITH_MESSAGE',
  SYNTHETIC_PULL_REQUESTS = 'SYNTHETIC_PULL_REQUESTS',
  GROUPED_PULL_REQUESTS = 'GROUPED_PULL_REQUESTS',
  EXPLICIT_USER_ID = 'EXPLICIT_USER_ID',
  SYNTHETIC_PR_AND_TICKET = 'SYNTHETIC_PR_AND_TICKET',
}

export enum BedrockSummarySendMode {
  ONCE = 'ONCE',
  FORCE = 'FORCE',
}
