import { IUser, OrganizationToken, UserRole, UserToken } from '@shared/types';
import { get } from '@web/common/api';
import { debounce } from 'lodash';
import { stringifyUrl } from 'query-string/base';
import * as React from 'react';

const SELECT_USER_LIMIT = 10;
const NO_RESULTS = [];

interface SearchOptions {
  organizationToken?: OrganizationToken;
  reportsOnly?: boolean;
  omitUserTokens?: UserToken[];
  allowEmptySearch?: boolean;
  roleFilter?: UserRole;
  includeOnly?: UserToken[];
}

export const useSelectUserSearch = ({
  organizationToken,
  reportsOnly = false,
  omitUserTokens = [],
  allowEmptySearch = false,
  roleFilter,
  includeOnly,
}: SearchOptions) => {
  const [results, setResults] = React.useState<IUser[]>(NO_RESULTS);

  const clearSearch = () => {
    setResults(NO_RESULTS);
  };

  const search = React.useMemo(() => {
    return debounce(async (searchQuery: string) => {
      if (searchQuery.length === 0 && !allowEmptySearch) {
        clearSearch();
        return;
      }

      const userSearchUrl = stringifyUrl({
        url: '/users/select/search',
        query: {
          // increase requested limit so that filtered out results will always meet limit if possible
          limit: SELECT_USER_LIMIT + omitUserTokens.length,
          search: searchQuery,
          organization: organizationToken,
          reportsOnly,
          role: roleFilter,
          includeOnly,
        },
      });

      try {
        const userResults = await get<IUser[]>(userSearchUrl);
        setResults(
          userResults
            .filter((user) => !omitUserTokens.includes(user.token))
            .slice(0, SELECT_USER_LIMIT),
        );
      } catch (error) {
        console.error(error);
      }
    }, 200);
  }, [omitUserTokens, reportsOnly, allowEmptySearch, organizationToken]);

  return {
    search,
    clearSearch,
    users: results,
  };
};
