import { useFindScribeEntries } from '@client/ImpactsClient';
import { useGetTeamMembers } from '@client/UsersClient';
import { formatDate } from '@shared/formatDate';
import { IImpact } from '@shared/impacts';
import { UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { Markdown } from '@web/components/Markdown';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { SelectDateRange } from '@web/components/SelectDate';
import { UserMessage } from '@web/components/UserMessage';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header3 } from '@web/components/typography';
import { Empty, Skeleton } from 'antd';
import { endOfDay, startOfDay } from 'date-fns';
import { pickBy } from 'lodash';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

export const ScribePage: React.FC = () => {
  const { isMobile } = useResponsive();
  const { managesPeople, managesManagers } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams({
    scope: 'user',
  });
  const params = pickBy({
    scope: searchParams.get('scope'),
    userToken: searchParams.get('userToken'),
    startDate: searchParams.get('startDate'),
    endDate: searchParams.get('endDate'),
  });

  const { data: teamMembers } = useGetTeamMembers();
  const { data: searchResults } = useFindScribeEntries(
    params.scope as any,
    params.userToken as UserToken | undefined,
    params.startDate
      ? startOfDay(new Date(params.startDate)).toJSON()
      : undefined,
    params.endDate ? endOfDay(new Date(params.endDate)).toJSON() : undefined,
  );
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  if (!searchResults || !teamMembers) {
    return (
      <PageContent>
        <PageHeader title="Scribe" mobileTitle="Scribe" />
        <Pane>
          <Skeleton />
        </Pane>
      </PageContent>
    );
  }

  const setScope = (
    scope: 'user' | 'org-chart' | 'direct-reports',
    userToken?: UserToken,
  ) => {
    setSearchParams((prev) => {
      prev.delete('scope');
      prev.delete('userToken');
      if (prev.get('scope') !== 'user') {
        prev.set('scope', scope);
      }
      if (userToken) {
        prev.set('userToken', userToken);
      }
      return prev;
    });
  };

  const handleDateRangeChanged = (
    newStart: string | null,
    newEnd: string | null,
  ) => {
    setStartDate(newStart);
    setEndDate(newEnd);
    setSearchParams((prev) => {
      if (newStart) {
        prev.set('startDate', newStart);
      } else {
        prev.delete('startDate');
      }
      if (newEnd) {
        prev.set('endDate', newEnd);
      } else {
        prev.delete('endDate');
      }
      return prev;
    });
  };

  const hasImpactEntries = searchResults.impacts?.length > 0;
  return (
    <PageContent>
      <PageHeader title="Scribe" mobileTitle="Scribe" />
      <Pane>
        <Column gap={12}>
          <Column gap={3}>
            {managesPeople ? (
              <Row gap={12} style={{ flexWrap: 'wrap' }}>
                <FilterOption
                  selected={params.scope === 'user' && !params.userToken}
                  onClick={() => {
                    setScope('user');
                  }}
                >
                  You
                </FilterOption>
                <FilterOption
                  selected={params.scope === 'direct-reports'}
                  onClick={() => {
                    setScope('direct-reports');
                  }}
                >
                  Your direct reports
                </FilterOption>
                {managesManagers && (
                  <FilterOption
                    selected={params.scope === 'org-chart'}
                    onClick={() => {
                      setScope('org-chart');
                    }}
                  >
                    Your entire team
                  </FilterOption>
                )}
                {teamMembers.map((u) => {
                  const disabled =
                    params.scope === 'user' && params.userToken === u.token;
                  return (
                    <FilterOption
                      key={u.token}
                      selected={disabled}
                      onClick={() => {
                        setScope('user', u.token);
                      }}
                    >
                      {u.name}
                    </FilterOption>
                  );
                })}
              </Row>
            ) : undefined}
            <Column style={{ maxWidth: 380 }}>
              <SelectDateRange
                start={startDate}
                end={endDate}
                onChange={handleDateRangeChanged}
              />
            </Column>
          </Column>
          {hasImpactEntries ? (
            <Column gap={12}>
              {searchResults.impacts.map((impact) => (
                <DetailedJournalCard
                  key={impact.token}
                  impact={impact}
                  showUser={params.scope !== 'user'}
                />
              ))}
            </Column>
          ) : (
            <Empty />
          )}
        </Column>
      </Pane>
      {isMobile && <Spacer size={60} />}
    </PageContent>
  );
};

export const DetailedJournalCard: React.FC<{
  impact: IImpact;
  showUser: boolean;
}> = ({ impact, showUser }) => {
  return (
    <Column style={{ padding: '14px 14px 0px', border: '1px solid #eee' }}>
      {showUser ? (
        <UserMessage user={impact.user} date={impact.date} large />
      ) : undefined}
      <Row style={{ alignItems: 'flex-start' }}>
        <Header3>{impact.title}</Header3>
        <GrowingSpacer min={10} />
        {!showUser ? <Header3>{formatDate(impact.date)}</Header3> : undefined}
      </Row>
      <Column style={{ maxWidth: 824 }}>
        <Markdown value={impact.descriptionMarkdown} />
      </Column>
    </Column>
  );
};

const FilterOption: React.FC<{
  onClick: () => void;
  selected: boolean;
  children?: React.ReactNode;
}> = ({ onClick, selected, children }) => {
  if (selected) {
    return <Header3 style={{ fontWeight: 600 }}>{children}</Header3>;
  }
  return <UnselectedOption onClick={onClick}>{children}</UnselectedOption>;
};

const UnselectedOption = styled(Header3)`
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
`;
