import {
  BedrockBatchToken,
  BedrockMechanism,
  BedrockReportSendMode,
  BedrockRunOpts,
  BedrockRunToken,
  BedrockSummarySendMode,
  ChannelList,
  CreateBedrockBatchRequest,
  GetBedrockBatchesResponse,
  GetBedrockConfigurationResponse,
  GetBedrockRunsResponse,
  IBedrockEnrollment,
  IBedrockRun,
  IChannelInfo,
  RunBedrockRequest,
} from '@shared/bedrock';
import { OrganizationToken, UserToken } from '@shared/types';
import { del, get, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useScribePageEnabled() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/scribe-page-enabled`,
    query: {},
  });

  return useApi<boolean>(url);
}

export function scribePageEnabled() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/scribe-page-enabled`,
    query: {},
  });

  return get<boolean>(url);
}

export function runBedrock(
  organizationToken: OrganizationToken,
  request: RunBedrockRequest,
) {
  return post<RunBedrockRequest>(
    `/bedrock-configuration/organization/${organizationToken}/run`,
    request,
  );
}

export function sendBedrockIndividualSummary(
  organizationToken: OrganizationToken,
  batchToken: BedrockBatchToken,
  sendMode: BedrockSummarySendMode,
  runToken?: BedrockRunToken,
) {
  return post<{
    sendMode: BedrockSummarySendMode;
    runToken: `br_${string}`;
  }>(
    `/bedrock-configuration/organization/${organizationToken}/batches/${batchToken}/individual-summary`,
    {
      sendMode: sendMode,
      runToken: runToken,
    },
  );
}

export function sendBedrockManagerSummary(
  organizationToken: OrganizationToken,
  batchToken: BedrockBatchToken,
  sendMode: BedrockSummarySendMode,
  runToken?: BedrockRunToken,
) {
  return post<{
    sendMode: BedrockSummarySendMode;
    runToken: `br_${string}`;
  }>(
    `/bedrock-configuration/organization/${organizationToken}/batches/${batchToken}/manager-summary`,
    {
      sendMode: sendMode,
      runToken: runToken,
    },
  );
}

export function createBedrockBatch(
  organizationToken: OrganizationToken,
  request: CreateBedrockBatchRequest,
) {
  return post<CreateBedrockBatchRequest>(
    `/bedrock-configuration/organization/${organizationToken}/batches`,
    request,
  );
}

export function useBedrockBatches(organizationToken: OrganizationToken) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/batches`,
    query: {},
  });

  return useApi<GetBedrockBatchesResponse>(url);
}

export function bedrockBatches(organizationToken: OrganizationToken) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/batches`,
    query: {},
  });

  return get<GetBedrockBatchesResponse>(url);
}

export function prepareBedrockBatch(
  organizationToken: OrganizationToken,
  bedrockBatchToken: BedrockBatchToken,
  mechanism: BedrockMechanism,
  runOpts: BedrockRunOpts,
) {
  return post<{
    mechanism: BedrockMechanism;
    runOpts: BedrockRunOpts;
  }>(
    `/bedrock-configuration/organization/${organizationToken}/batches/${bedrockBatchToken}/prepare`,
    {
      mechanism: mechanism,
      runOpts: runOpts,
    },
  );
}

export function clearBedrockPrompt(
  organizationToken: OrganizationToken,
  bedrockBatchToken: BedrockBatchToken,
  runToken?: BedrockRunToken,
) {
  return post<{ runToken: `br_${string}` }>(
    `/bedrock-configuration/organization/${organizationToken}/batches/${bedrockBatchToken}/prompt`,
    { runToken: runToken },
  );
}

export function sendBedrockBatch(
  organizationToken: OrganizationToken,
  bedrockBatchToken: BedrockBatchToken,
  mechanism: BedrockMechanism,
  sendMode: BedrockReportSendMode,
) {
  return post<{
    mechanism: BedrockMechanism;
    sendMode: BedrockReportSendMode;
  }>(
    `/bedrock-configuration/organization/${organizationToken}/batches/${bedrockBatchToken}/send`,
    {
      mechanism: mechanism,
      sendMode: sendMode,
    },
  );
}

export function useBedrockRuns(
  organizationToken: OrganizationToken,
  bedrockBatchToken: BedrockBatchToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/batches/${bedrockBatchToken}`,
    query: {},
  });

  return useApi<GetBedrockRunsResponse>(url);
}

export function bedrockRuns(
  organizationToken: OrganizationToken,
  bedrockBatchToken: BedrockBatchToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/batches/${bedrockBatchToken}`,
    query: {},
  });

  return get<GetBedrockRunsResponse>(url);
}

export function useBedrockRun(
  organizationToken: OrganizationToken,
  batchToken: BedrockBatchToken,
  runToken: BedrockRunToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/batches/${batchToken}/runs/${runToken}`,
    query: {},
  });

  return useApi<IBedrockRun>(url);
}

export function bedrockRun(
  organizationToken: OrganizationToken,
  batchToken: BedrockBatchToken,
  runToken: BedrockRunToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/batches/${batchToken}/runs/${runToken}`,
    query: {},
  });

  return get<IBedrockRun>(url);
}

export function prepareBedrockRun(
  organizationToken: OrganizationToken,
  batchToken: BedrockBatchToken,
  runToken: BedrockRunToken,
  mechanism: BedrockMechanism,
  runOpts: BedrockRunOpts,
) {
  return post<{
    mechanism: BedrockMechanism;
    runOpts: BedrockRunOpts;
  }>(
    `/bedrock-configuration/organization/${organizationToken}/batches/${batchToken}/runs/${runToken}/prepare`,
    {
      mechanism: mechanism,
      runOpts: runOpts,
    },
  );
}

export function sendBedrockRun(
  organizationToken: OrganizationToken,
  batchToken: BedrockBatchToken,
  runToken: BedrockRunToken,
  mechanism: BedrockMechanism,
  sendMode: BedrockReportSendMode,
) {
  return post<{
    mechanism: BedrockMechanism;
    sendMode: BedrockReportSendMode;
  }>(
    `/bedrock-configuration/organization/${organizationToken}/batches/${batchToken}/runs/${runToken}/send`,
    {
      mechanism: mechanism,
      sendMode: sendMode,
    },
  );
}

export function generate(
  organizationToken: OrganizationToken,
  userToken: UserToken,
  mechanism: 'journal' | 'dm',
) {
  return post<{}>(
    `/bedrock-configuration/generate/${organizationToken}/${userToken}/${mechanism}`,
  );
}

export function useEnrolledBedrockUsersInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/enrolled`,
    query: {},
  });

  return useApi<IBedrockEnrollment[]>(url);
}

export function enrolledBedrockUsersInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/enrolled`,
    query: {},
  });

  return get<IBedrockEnrollment[]>(url);
}

export function useEnrolledBedrockUsers() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/enrolled`,
    query: {},
  });

  return useApi<IBedrockEnrollment[]>(url);
}

export function enrolledBedrockUsers() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/enrolled`,
    query: {},
  });

  return get<IBedrockEnrollment[]>(url);
}

export function useBedrockConfigurationInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/configuration`,
    query: {},
  });

  return useApi<GetBedrockConfigurationResponse>(url);
}

export function bedrockConfigurationInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/configuration`,
    query: {},
  });

  return get<GetBedrockConfigurationResponse>(url);
}

export function useBedrockEnrolledChannelsInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/enrolled-channels`,
    query: {},
  });

  return useApi<IChannelInfo[]>(url);
}

export function bedrockEnrolledChannelsInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/enrolled-channels`,
    query: {},
  });

  return get<IChannelInfo[]>(url);
}

export function useBedrockEnrolledChannelsAdmin() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/enrolled-channels`,
    query: {},
  });

  return useApi<IChannelInfo[]>(url);
}

export function bedrockEnrolledChannelsAdmin() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/enrolled-channels`,
    query: {},
  });

  return get<IChannelInfo[]>(url);
}

export function useBedrockConfigurationAdmin() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/configuration`,
    query: {},
  });

  return useApi<GetBedrockConfigurationResponse>(url);
}

export function bedrockConfigurationAdmin() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/configuration`,
    query: {},
  });

  return get<GetBedrockConfigurationResponse>(url);
}

export function useBedrockEligibleChannelsInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/channels`,
    query: {},
  });

  return useApi<ChannelList>(url);
}

export function bedrockEligibleChannelsInternal(
  organizationToken: OrganizationToken,
) {
  const url = stringifyUrl({
    url: `/bedrock-configuration/organization/${organizationToken}/channels`,
    query: {},
  });

  return get<ChannelList>(url);
}

export function useBedrockEligibleChannelsAdmin() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/channels`,
    query: {},
  });

  return useApi<ChannelList>(url);
}

export function bedrockEligibleChannelsAdmin() {
  const url = stringifyUrl({
    url: `/bedrock-configuration/channels`,
    query: {},
  });

  return get<ChannelList>(url);
}

export function addBedrockChannelsInternal(
  organizationToken: OrganizationToken,
  channelIds: string[],
) {
  return post<{ channelIds: string[] }, void>(
    `/bedrock-configuration/organization/${organizationToken}/channels`,
    { channelIds: channelIds },
  );
}

export function addBedrockChannelsAdmin(channelIds: string[]) {
  return post<{ channelIds: string[] }, void>(
    `/bedrock-configuration/channels`,
    { channelIds: channelIds },
  );
}

export function deleteBedrockChannelInternal(
  organizationToken: OrganizationToken,
  channelId: string,
) {
  return del(
    `/bedrock-configuration/organization/${organizationToken}/channels/${channelId}`,
  );
}

export function deleteBedrockChannelAdmin(channelId: string) {
  return del(`/bedrock-configuration/channels/${channelId}`);
}

export function enrollBedrockUsersInternal(
  organizationToken: OrganizationToken,
  userToken: UserToken,
) {
  return post<{ userToken: `u_${string}` }>(
    `/bedrock-configuration/organization/${organizationToken}/enroll`,
    { userToken: userToken },
  );
}

export function enrollBedrockUser(userToken: UserToken) {
  return post<{ userToken: `u_${string}` }>(`/bedrock-configuration/enroll`, {
    userToken: userToken,
  });
}

export function deleteEnrolledBedrockUser(userToken: UserToken) {
  return del(`/bedrock-configuration/enrolled/${userToken}`);
}

export function deleteEnrolledBedrockUserInternal(
  userToken: UserToken,
  organizationToken: OrganizationToken,
) {
  return del(
    `/bedrock-configuration/organization/${organizationToken}/enrolled/${userToken}`,
  );
}
