import { PlusOutlined } from '@ant-design/icons';
import { OrganizationToken } from '@shared/types';
import { Column } from '@web/components/layout';
import { Button, message } from 'antd';
import * as React from 'react';

import { AddChannelsModal } from './AddChannelsModal';

export const AddChannelsButton: React.FC<{
  organizationToken?: OrganizationToken;
  onAddChannels: (channelIds: string[]) => Promise<void>;
}> = ({ organizationToken, onAddChannels }) => {
  const [showAddChannelsModal, setShowAddChannelsModal] = React.useState(false);

  const handleAddChannels = async (channelIds: string[]) => {
    try {
      await onAddChannels(channelIds);
      setShowAddChannelsModal(false);
    } catch (error) {
      void message.error(channelIds);
    }
  };

  return (
    <Column>
      <Button
        type="primary"
        onClick={() => {
          setShowAddChannelsModal(true);
        }}
      >
        <PlusOutlined /> Add Channels
      </Button>
      {showAddChannelsModal && (
        <AddChannelsModal
          organizationToken={organizationToken}
          onAdd={handleAddChannels}
          onCancel={() => {
            setShowAddChannelsModal(false);
          }}
        />
      )}
    </Column>
  );
};
