import { BedrockBatchPage } from '@web/bedrock/internal/BedrockBatchPage';
import { BedrockBatchesPage } from '@web/bedrock/internal/BedrockBatchesPage';
import { InternalBedrockPage } from '@web/bedrock/internal/InternalBedrockPage';
import { IS_PRODUCTION } from '@web/common/const';
import { GithubFeaturePage } from '@web/github/GithubFeaturePage';
import { GithubMembersPage } from '@web/github/GithubMembersPage';
import { GithubPullsPage } from '@web/github/GithubPullsPage';
import { NotificationTemplatePage } from '@web/notifications/NotificationTemplatePage';
import NotificationTemplatesPage from '@web/notifications/NotificationTemplatesPage';
import { NotificationsPage } from '@web/notifications/NotificationsPage';
import { EditSummaryPage } from '@web/performance/internal/EditSummaryPage';
import ListSummariesPage from '@web/performance/internal/ListSummariesPage';
import UserActivityPage from '@web/users/UserPage/UserActivityPage';
import { WorkdayFakePayloadPage } from '@web/workday/WorkdayFakePayloadPage';
import { WorkdayFakePayloadsPage } from '@web/workday/WorkdayFakePayloadsPage';
import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { EditUserPage, OrganizationPage, UsersPage } from '../lazy';

export const internalRoutes = () => (
  <Route path="organizations">
    <Route path=":organizationToken">
      <Route index element={<Navigate to="details" replace />} />
      <Route path="scribe">
        <Route index element={<Navigate to="config" replace />} />
        <Route path="config" element={<InternalBedrockPage />} />
        <Route path="batches">
          <Route index element={<BedrockBatchesPage />} />
          <Route path=":batchToken" element={<BedrockBatchPage />} />
        </Route>
      </Route>
      <Route path="performance">
        <Route index element={<ListSummariesPage />} />
        <Route path="new" element={<EditSummaryPage />} />
        <Route path=":performanceSummaryToken" element={<EditSummaryPage />} />
      </Route>
      <Route path="details" element={<OrganizationPage />} />
      <Route path="github">
        <Route index element={<Navigate to="feature" />} />
        <Route path="feature" element={<GithubFeaturePage />} />
        <Route path="members" element={<GithubMembersPage />} />
        <Route path="pulls" element={<GithubPullsPage />} />
      </Route>
      <Route path="users">
        <Route index element={<UsersPage />} />
        <Route path=":userToken" element={<EditUserPage />} />
        <Route path=":userToken/activity" element={<UserActivityPage />} />
      </Route>
      <Route path="notifications">
        <Route index element={<NotificationsPage />} />
        <Route path="templates">
          <Route index element={<NotificationTemplatesPage />} />
          <Route
            path=":notificationTemplateToken"
            element={<NotificationTemplatePage />}
          />
          <Route path="new" element={<NotificationTemplatePage />} />
        </Route>
      </Route>
      {!IS_PRODUCTION ? (
        <Route path="workday">
          <Route index element={<WorkdayFakePayloadsPage />} />
          <Route
            path=":workdayFakePayloadToken"
            element={<WorkdayFakePayloadPage />}
          />
          <Route path="new" element={<WorkdayFakePayloadPage />} />
        </Route>
      ) : undefined}
    </Route>
  </Route>
);
