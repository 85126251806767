import { ChannelList } from '@shared/bedrock';
import { Select } from 'antd';
import * as React from 'react';

interface SelectChannelProps {
  channels: ChannelList;
  disabled?: boolean;
  excludeChannelIds: string[];
  onChange?: (newValue: string[]) => void;
}
interface ChannelOption {
  /* The channel name. */
  label: string;
  /* The channel id. */
  value: string;
}
export const SelectChannels: React.FC<SelectChannelProps> = ({
  channels,
  disabled,
  excludeChannelIds,
  onChange,
}) => {
  const hiddenSet = new Set<string>(excludeChannelIds);

  console.log('SelectChannels', channels);
  if (!channels) {
    return undefined;
  }

  const options = channels
    .filter((channel) => !hiddenSet.has(channel.id))
    .map(
      (channel): ChannelOption => ({ label: channel.name, value: channel.id }),
    );

  const handleSelectChange = async (channelIds: string[]) => {
    onChange?.(channelIds);
  };

  return (
    <Select
      mode="multiple"
      disabled={disabled}
      notFoundContent={null}
      showSearch
      options={options}
      onChange={handleSelectChange}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};
