import { OrganizationToken, UserToken } from './types';

export enum Feature {
  GITHUB_INTEGRATION = 'GITHUB_INTEGRATION',
  REFLECTIONS = 'REFLECTIONS',
  TEAM_ONBOARDING_BANNER = 'TEAM_ONBOARDING_BANNER',
  HIGHLIGHT_DISABLED = 'HIGHLIGHT_DISABLED',
  REFLECTION_TAGS_ENABLED = 'REFLECTION_TAGS_ENABLED',
  REVIEW_CYCLES = 'REVIEW_CYCLES',
  SKIP_INITIAL_SLACK_SYNC = 'SKIP_INITIAL_SLACK_SYNC',
  SURVEYS = 'SURVEYS',
  USE_HRBPS = 'USE_HRBPS',
  EMAIL_NOTIFICATIONS = 'EMAIL_NOTIFICATIONS',
  JOURNAL = 'JOURNAL',
  SLACK = 'SLACK',
  MESSAGES_ENABLED = 'MESSAGES_ENABLED',
  GOALS = 'GOALS',
  PUBLIC_GOALS = 'PUBLIC_GOALS',
  REVIEW_PREVIEW = 'SELF_REVIEW_PREVIEW',
  SHOW_RECENT_JOURNAL_ENTRIES_IN_REVIEW = 'SHOW_RECENT_JOURNAL_ENTRIES_IN_REVIEW',
  ALLOW_REVIEW_CYCLE_EXPORT = 'ALLOW_REVIEW_CYCLE_EXPORT',
  ENABLE_1ON1s = 'ENABLE_1ON1s',
  SUPRESS_CYCLE_PEER_REQUEST_NOTIFICATIONS = 'SUPRESS_CYCLE_PEER_REQUEST_NOTIFICATIONS',
  ENABLE_ALIGNMENT_INDICATORS = 'ENABLE_ALIGNMENT_INDICATORS',
  ALIGNMENT_INDICATOR_FAST_MODE = 'ALIGNMENT_INDICATOR_FAST_MODE',
  SKIP_MANAGER_CYCLE_NOTIFICATIONS = 'SKIP_MANAGER_CYCLE_NOTIFICATIONS',
  ALLOW_MULTIPLE_MANAGERS = 'ALLOW_MULTIPLE_MANAGERS',
  JOURNAL_RELATED_GOALS = 'JOURNAL_RELATED_GOALS',
  SKIP_VALIDATE_SLACK_ACCESS = 'SKIP_VALIDATE_SLACK_ACCESS',
  ADMIN_USER_ADD = 'ADMIN_USER_ADD',
  FORCE_REFRESH = 'FORCE_REFRESH',
  TEAM_METRICS = 'TEAM_METRICS',
  ENABLE_REVIEW_CYCLE_CLOSING = 'ENABLE_REVIEW_CYCLE_CLOSING',
  PUBLIC_JOURNAL_ENABLED = 'PUBLIC_JOURNAL_ENABLED',
  ANALYTICS_DASHBOARD = 'ANALYTICS_DASHBOARD',
  ENABLE_CHECK_INS = 'ENABLE_CHECK_INS',
  PERFORMANCE_SUMMARIES = 'PERFORMANCE_SUMMARIES',
  ENABLE_BEDROCK = 'ENABLE_BEDROCK',
  ALLOW_BEDROCK_PRIVATE_CHANNELS = 'ALLOW_BEDROCK_PRIVATE_CHANNELS',
  DISABLE_BEDROCK_CHANNEL_CACHE = 'DISABLE_BEDROCK_CHANNEL_CACHE',
  UPWARD_FEEDBACK_ENABLED = 'UPWARD_FEEDBACK_ENABLED',
  ENABLE_BEDROCK_LINEAR_PARSING = 'ENABLE_BEDROCK_LINEAR_PARSING',
  SCRIBE_PAGE_ENABLED = 'SCRIBE_PAGE_ENABLED',
}

export interface IFeatureValue {
  booleanValue?: boolean;
  stringValue?: string;
  numberValue?: number;
}

export interface IFeatureOverride extends IFeatureValue {
  feature: Feature;
  entityToken: UserToken | OrganizationToken;
  createdDate: Date;
  updatedDate: Date;
}

export const featureDefaults: Record<Feature, IFeatureValue> = {
  [Feature.GITHUB_INTEGRATION]: {
    booleanValue: false,
  },
  [Feature.REFLECTIONS]: {
    booleanValue: false,
  },
  [Feature.TEAM_ONBOARDING_BANNER]: {
    booleanValue: false,
  },
  [Feature.HIGHLIGHT_DISABLED]: {
    booleanValue: false,
  },
  [Feature.REFLECTION_TAGS_ENABLED]: {
    booleanValue: false,
  },
  [Feature.REVIEW_CYCLES]: {
    booleanValue: false,
  },
  [Feature.SKIP_INITIAL_SLACK_SYNC]: {
    booleanValue: false,
  },
  [Feature.SURVEYS]: {
    booleanValue: false,
  },
  [Feature.USE_HRBPS]: {
    booleanValue: false,
  },
  [Feature.SLACK]: {
    booleanValue: true,
  },
  [Feature.EMAIL_NOTIFICATIONS]: {
    booleanValue: false,
  },
  [Feature.JOURNAL]: {
    booleanValue: true,
  },
  [Feature.MESSAGES_ENABLED]: {
    booleanValue: false,
  },
  [Feature.GOALS]: {
    booleanValue: false,
  },
  [Feature.PUBLIC_GOALS]: {
    booleanValue: false,
  },
  [Feature.REVIEW_PREVIEW]: {
    booleanValue: false,
  },
  [Feature.SHOW_RECENT_JOURNAL_ENTRIES_IN_REVIEW]: {
    booleanValue: false,
  },
  [Feature.ALLOW_REVIEW_CYCLE_EXPORT]: {
    booleanValue: false,
  },
  [Feature.ENABLE_1ON1s]: {
    booleanValue: false,
  },
  [Feature.SUPRESS_CYCLE_PEER_REQUEST_NOTIFICATIONS]: {
    booleanValue: false,
  },
  [Feature.ENABLE_ALIGNMENT_INDICATORS]: {
    booleanValue: false,
  },
  [Feature.ALIGNMENT_INDICATOR_FAST_MODE]: {
    booleanValue: false,
  },
  [Feature.SKIP_MANAGER_CYCLE_NOTIFICATIONS]: {
    booleanValue: false,
  },
  [Feature.ALLOW_MULTIPLE_MANAGERS]: {
    booleanValue: false,
  },
  [Feature.JOURNAL_RELATED_GOALS]: {
    booleanValue: false,
  },
  [Feature.SKIP_VALIDATE_SLACK_ACCESS]: {
    booleanValue: false,
  },
  [Feature.ADMIN_USER_ADD]: {
    booleanValue: false,
  },
  [Feature.FORCE_REFRESH]: {
    booleanValue: false,
  },
  [Feature.TEAM_METRICS]: {
    booleanValue: false,
  },
  [Feature.ENABLE_REVIEW_CYCLE_CLOSING]: {
    booleanValue: false,
  },
  [Feature.PUBLIC_JOURNAL_ENABLED]: {
    booleanValue: false,
  },
  [Feature.ANALYTICS_DASHBOARD]: {
    booleanValue: false,
  },
  [Feature.ENABLE_CHECK_INS]: {
    booleanValue: false,
  },
  [Feature.PERFORMANCE_SUMMARIES]: {
    booleanValue: false,
  },
  [Feature.ENABLE_BEDROCK]: {
    booleanValue: false,
  },
  [Feature.ALLOW_BEDROCK_PRIVATE_CHANNELS]: {
    booleanValue: false,
  },
  [Feature.DISABLE_BEDROCK_CHANNEL_CACHE]: {
    booleanValue: false,
  },
  [Feature.UPWARD_FEEDBACK_ENABLED]: {
    booleanValue: false,
  },
  [Feature.ENABLE_BEDROCK_LINEAR_PARSING]: {
    booleanValue: false,
  },
  [Feature.SCRIBE_PAGE_ENABLED]: {
    booleanValue: false,
  },
};

export interface IFeatureDetailsResponse {
  featureDefaults: Record<Feature, IFeatureValue>;
  featureOverrides: Record<Feature, IFeatureOverride>;
}

export interface IUpdateFeatureOverridesRequest {
  overrides: Array<Partial<IFeatureOverride>>;
}
